export function waitForScriptToBeAvailableThenExecute(verifyVar, callbackFn) {
  // console.log('waitForScriptToBeAvailableThenExecute()');
  new Promise((resolve, reject) => {
    var checkInterval = 0;

    function checkIfVarExists() {
      // console.log('window.dzsap_part_starRatings_loaded - ', window.dzsap_part_starRatings_loaded);
      // console.log('verifyVar -', verifyVar);

      if (verifyVar) {
        clearInterval(checkInterval);
        resolve("var exists");
      }
    }

    checkIfVarExists();
    checkInterval = setInterval(checkIfVarExists, 300);

    setTimeout(() => {
      // resolve('timeout');
      reject("timeout");
    }, 5000);
  })
    .then((resolve) => {
      callbackFn(resolve);
    })
    .catch((err) => {
      // console.log(err);
      // callbackFn('error');
    });
}
