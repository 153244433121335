import { documentReady } from "../esjquery/_esjquery";
import { $es } from "../esjquery/_esjquery";
import { waitForScriptToBeAvailableThenExecute } from "./jsinc/_dzsCommon";

documentReady(() => {
  const sendRequestForRegister = () => {
    jQuery.ajax({
      type: "POST",
      url: armpay_settings.site_url + "/wp-json/armpay/v1/user-register",
      data: jQuery("form.armpay-register-form").serialize(),
      success: (e) => {
        console.log(e);

        const $submit = $es(".armpay-submit-btn");
        /** @type HTMLInputElement */
        const $submit_ = $submit.$el;

        $es(".form-group").removeClass("invalid");

        if (e.data.validation_error) {
          $es(".validation-error").html(e.data.validation_error);
        }

        if (e.data.invalid_field) {
          $es(`*[name="${e.data.invalid_field}"]`).parent().addClass("invalid");
          $es(`*[name="${e.data.invalid_field}"]`)
            .parent()
            .$el.after($es(".validation-error").$el);
        }

        const HIDE_DELAY = 5000;
        if (e.success) {
          $es(".armpay-register-form").$el.hidden = true;
          $es(".armpay-login-form").$el.hidden = true;
          $es(".armpay--success-con").$el.hidden = false;
          $es(".armpay--success-con .armpay--success-con--desc").$el.innerHTML =
            e.data.success_message;

          setTimeout(() => {
            hideForm();
          }, HIDE_DELAY);
        }

        $submit.removeClass("btn-disabled");
        $submit_.disabled = false;
      },
    });
  };

  const LABEL_MEMBER_REQUEST = "member-request";
  let armpay_settings = {};
  const $signupContainer = $es(".armpay-signup__container");
  const $signupForm = $es(".armpay-signup");
  /** @type {esJquery} */
  const $submit = $es(".armpay-submit-btn");
  var capchaHolder = null;

  init();

  function init() {
    const $body = $es("body");

    const $formBg = $es(".armpay-signup__container--bg");
    const $btnClose = $es(".btn-close");

    const $switchToLogin = $es('a[href="#armpay-switch-to-login"]');
    const $switchToRegister = $es('a[href="#armpay-switch-to-register"]');
    const $formRegister = $es("form.armpay-register-form");
    const $armPaySettings = $es(".armpay-settings-data").eq(0);

    var grecaptchaKeyEl = $es(".recapcha-site-key");
    var grecaptchaKey = "";
    if (grecaptchaKeyEl.$el) {
      grecaptchaKey = grecaptchaKeyEl.html();
    }

    console.log("grecaptchaKey- ", grecaptchaKey);

    if (grecaptchaKey) {
      setTimeout(() => {
        var script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
        document.body.appendChild(script); //or something of the likes

        script.onload = () => {
          console.log("rdy1", grecaptcha);
          grecaptcha.ready(function () {
            console.log("rdy", document.getElementById("recaptcha-container"));
            capchaHolder = grecaptcha.render(
              document.getElementById("recaptcha-container"),
              {
                sitekey: grecaptchaKey,
                size: "invisible",
                badge: "inline",
                callback: function (recaptchaToken) {
                  console.log("callback - ", recaptchaToken);
                  sendRequestForRegister();
                },
              },
            );
            console.log("capchaHolder", capchaHolder);
          });
        };
      }, 3000);
      // waitForScriptToBeAvailableThenExecute(window.grecaptcha, );
    }

    armpay_settings = JSON.parse($armPaySettings.html());

    if ($body.hasClass("single-tour")) {
      $es('*[name="tour-name"]').val(window.location.href);
    }

    $switchToLogin.on("click", (e) => {
      $es(".armpay-register-form").$el.hidden = true;
      $es(".armpay-login-form").$el.hidden = false;

      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      return false;
    });

    $formBg.on("click", (e) => {
      hideForm();
    });
    $btnClose.on("click", (e) => {
      hideForm();
    });
    $switchToRegister.on("click", (e) => {
      $es(".armpay-register-form").$el.hidden = false;
      $es(".armpay-login-form").$el.hidden = true;

      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      return false;
    });
    $es(document).on("click", `a[href="#${LABEL_MEMBER_REQUEST}"]`, (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      $signupForm.removeClass("sign-up--fade-out");
      $signupContainer.$el.hidden = false;

      return false;
    });

    $submit.on("click", (e) => {
      console.log("ceva submit");

      /** @type HTMLInputElement */
      const $submit_ = $submit.$el;
      console.log("$submit_- ", $submit_);
      // $submit_.disabled = true;
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      $submit.addClass("btn-disabled");
      $submit_.disabled = true;

      setTimeout(() => {
        $submit.removeClass("btn-disabled");
        $submit_.disabled = false;
      }, 2000);

      const $form = $formRegister;
      const params = new FormData($form.$el);

      submitRegister();

      return false;
    });
  }

  function submitRegister() {
    var grecaptchaKey = $es(".recapcha-site-key").html();
    // sendRequest();

    console.log("capchaHolder- ", capchaHolder);
    if (capchaHolder !== null) {
      window.grecaptcha
        .execute(capchaHolder, { action: "register" })
        .then(function (token) {
          console.log("token - ", token);
          // sendRequest();
        });
    }
  }

  function hideForm() {
    $signupForm.addClass("sign-up--fade-out");
    setTimeout(() => {
      $es(".form-group").removeClass("invalid");
      $es(".form-group input").val("");

      $signupForm.removeClass("sign-up--fade-out");
      $signupContainer.$el.hidden = true;
    }, 300);
  }
});
